import { Stroke, Style } from "ol/style";
import { SUPPORTED_HIGHWAY_TYPES } from "./supportedHighwayTypes";
import {
  getNodeStyleNormal,
  getNodeStyleClick,
  getNodeStyleHover,
} from "./node";

export function getEmissionRegulationStyleNormal(feature) {
  return getEmissionRegulationColorStyle(feature).concat(
    getNodeStyleNormal(feature),
  );
}
export function getEmissionRegulationStyleClick(feature) {
  return getEmissionRegulationColorStyle(feature, true, false).concat(
    getNodeStyleClick(feature),
  );
}
export function getEmissionRegulationStyleHover(feature) {
  return getEmissionRegulationColorStyle(feature, false, true).concat(
    getNodeStyleHover(feature),
  );
}
function getEmissionRegulationColorStyle(feature, click, hover) {
  let color, width;
  const emissionRegulation = findValueByKeyEndingWith(
    feature.getProperties(),
    "/emission_regulation",
  );
  const highway = findValueByKeyEndingWith(feature.getProperties(), "/highway");
  if (highway === undefined) {
    color = "black";
    width = 1;
  } else if (!SUPPORTED_HIGHWAY_TYPES.includes(highway)) {
    color = "grey";
    width = 1;
  } else if (emissionRegulation === "yes") {
    color = "#063970"; // dark blue
    width = 3;
  } else {
    color = "#abdbe3"; // light blue
    width = 1;
  }
  if (click) {
    return [
      new Style({
        stroke: new Stroke({
          color: color,
          width: width * 3,
        }),
      }),
      new Style({
        stroke: new Stroke({
          color: "#eab676", // orange
          width: width * 2,
        }),
      }),
    ];
  }
  if (hover) {
    return [
      new Style({
        stroke: new Stroke({
          color: "white",
          width: width * 4,
        }),
      }),
      new Style({
        stroke: new Stroke({
          color: color,
          width: width * 2,
        }),
      }),
    ];
  }
  return [
    new Style({
      stroke: new Stroke({
        color: color,
        width: width,
      }),
    }),
  ];
}
function findValueByKeyEndingWith(obj, suffix) {
  const key = Object.keys(obj).find((key) => key.endsWith(suffix));
  return obj[key];
}
