import "ol/ol.css";
import { Map, View } from "ol";
import { Control, defaults } from "ol/control";
import { fromLonLat } from "ol/proj";

import { AzureLoginControl } from "./controls/AzureLoginControl";

import { configureMonkeyFetch } from "./auth/monkeyFetch";
import { addSDOLayersAndControls } from "./config/SDOLayersAndControls";
import { addWKTLayerAndControl } from "./config/WKTLayerAndControl";
import { addDateInputControl } from "./config/DateInputControl";
import { addHeatmapLayersAndControls } from "./config/HeatmapLayersAndControls";
import { addGeocoderControl } from "./config/GeocoderControl";
import { addBasemapLayersAndControls } from "./config/BasemapLayersAndControls";
import { addLatLongControl } from "./config/LatLongControl";
import LayerGroup from "ol/layer/Group";
import { addLayersAndControls } from "./config/LayersAndControls";
import { addFeatureInfoControl } from "./config/FeatureInfoControl";

var view = new View({
  center: fromLonLat([-83.0991991, 42.3528697]),
  zoom: 13,
});

// monkeypatch fetch to use the flatgeobuf library
configureMonkeyFetch();

const azureLoginControl = new AzureLoginControl();

// Left Generic Controls
export const leftControlDiv = document.createElement("div");
leftControlDiv.id = "controls-left";
const leftControl = new Control({
  element: leftControlDiv,
});

const vectorFeaturesControlDiv = document.createElement("div");
vectorFeaturesControlDiv.className = "vector-feature";
const vectorFeaturesControl = new Control({
  element: vectorFeaturesControlDiv,
  target: leftControlDiv,
});
const map = new Map({
  target: "map",
  layers: [],
  view: view,
  controls: defaults({
    zoom: false,
    attribution: false,
    rotate: false,
  }).extend([azureLoginControl, leftControl]),
});
const heatmapLayerGroup = new LayerGroup();
addLatLongControl(map, view, leftControlDiv);
addBasemapLayersAndControls(map);
const dateInputControl = addDateInputControl(map, leftControlDiv);
azureLoginControl.once("propertychange", function () {
  map.addLayer(heatmapLayerGroup);
  addHeatmapLayersAndControls(
    map,
    azureLoginControl,
    dateInputControl,
    leftControlDiv,
    heatmapLayerGroup,
  );
  // SDO
  addSDOLayersAndControls(
    map,
    azureLoginControl,
    dateInputControl,
    leftControlDiv,
  );
  // vector
  map.addControl(vectorFeaturesControl);
  addWKTLayerAndControl(map, leftControlDiv, view)[0];
  addGeocoderControl(map);
  const featureInfoControl = addFeatureInfoControl(map);
  addLayersAndControls(
    map,
    azureLoginControl,
    featureInfoControl,
    vectorFeaturesControlDiv,
  );
});
