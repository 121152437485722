import { Stroke, Style } from "ol/style";
import { interpolateRdPu } from "d3-scale-chromatic";
import {
  getNodeStyleNormal,
  getNodeStyleClick,
  getNodeStyleHover,
} from "./node";
import { getLinearTagFlowStyle } from "./linearStyling";

export function getElevationStyleNormal(feature) {
  return getElevationColorStyle(feature).concat(getNodeStyleNormal(feature));
}
export function getElevationStyleClick(feature) {
  return getElevationColorStyle(feature, true, false).concat(
    getNodeStyleClick(feature),
  );
}
export function getElevationStyleHover(feature) {
  return getElevationColorStyle(feature, false, true).concat(
    getNodeStyleHover(feature),
  );
}
function getElevationColorStyle(feature, click, hover) {
  let color, width, backgroundColor, backgroundWidth;
  let orbisElevation = feature.get("orbis-elevation");
  if (orbisElevation === undefined) {
    orbisElevation = feature.get("orbis-elevation:linear");
  }
  if (orbisElevation === undefined) {
    color = "lightyellow";
    backgroundColor = "white";
    width = 2;
    backgroundWidth = 4;
    if (hover || click) {
      return [
        new Style({
          stroke: new Stroke({
            color: color,
            width: width,
          }),
        }),
        new Style({
          stroke: new Stroke({
            color: backgroundColor,
            width: backgroundWidth,
          }),
        }),
      ];
    }
    return [
      new Style({
        stroke: new Stroke({
          color: color,
          width: width,
        }),
      }),
    ];
  } else {
    if (hover) {
      backgroundColor = "black";
    } else if (click) {
      backgroundColor = "pink";
    } else {
      backgroundColor = "darkpurple";
    }
    if (hover || click) {
      width = 7;
      backgroundWidth = 10;
    } else {
      width = 5;
      backgroundWidth = 9;
    }
    const flowStyle = getLinearTagFlowStyle(
      orbisElevation,
      width,
      feature,
      interpolateRdPu,
    );
    return [
      flowStyle,
      new Style({
        stroke: new Stroke({
          color: backgroundColor,
          width: backgroundWidth,
        }),
      }),
    ];
  }
}
