import { Stroke, Icon, Style } from "ol/style";
import Point from "ol/geom/Point";

export function getStyleNormal(feature) {
  return getColorStyle(feature);
}
export function getStyleClick(feature) {
  return getColorStyle(feature, true, false).concat(getArrowStyle(feature));
}
export function getStyleHover(feature) {
  return getColorStyle(feature, false, true).concat(getArrowStyle(feature));
}
function getColorStyle(feature, click, hover) {
  let color, width;
  color = "violet";
  width = 3;
  if (click) {
    return [
      new Style({
        stroke: new Stroke({
          color: color,
          width: width * 3,
        }),
      }),
      new Style({
        stroke: new Stroke({
          color: "darkviolet",
          width: width * 2,
        }),
      }),
    ];
  }
  if (hover) {
    return [
      new Style({
        stroke: new Stroke({
          color: "white",
          width: width * 4,
        }),
      }),
      new Style({
        stroke: new Stroke({
          color: color,
          width: width * 2,
        }),
      }),
    ];
  }
  return [
    new Style({
      stroke: new Stroke({
        color: color,
        width: width,
      }),
    }),
  ];
}
function getArrowStyle(feature) {
  const styles = [];
  if (typeof feature.getGeometry().forEachSegment === "function") {
    feature.getGeometry().forEachSegment(function (start, end) {
      const mid = [(start[0] + end[0]) / 2, (start[1] + end[1]) / 2];
      const dx = end[0] - start[0];
      const dy = end[1] - start[1];
      const rotation = Math.atan2(dy, dx);
      // arrows
      styles.push(
        new Style({
          geometry: new Point(mid),
          image: new Icon({
            src: "icon-arrow.png",
            anchor: [0.75, 0.5],
            rotateWithView: true,
            rotation: -rotation,
            color: "grey",
            scale: [0.5, 0.5],
          }),
        }),
      );
    });
  }
  return styles;
}
