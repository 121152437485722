import { Stroke, Style } from "ol/style";

export function getHDLaneBorderStyleNormal(feature) {
  return getHDLaneBorderColorStyle(feature);
}
export function getHDLaneBorderStyleClick(feature) {
  return getHDLaneBorderColorStyle(feature, true, false);
}
export function getHDLaneBorderStyleHover(feature) {
  return getHDLaneBorderColorStyle(feature, false, true);
}
function getHDLaneBorderColorStyle(feature, click, hover) {
  let hdmapType = feature.get("hdmap_type");

  if (hdmapType !== undefined) {
    if (hdmapType !== "lane_border") {
      return;
    }
  }

  let type = feature.get("type");
  if (type === "ORBIS_RELATION") {
    return;
  }

  let laneBorderType = feature.get("lane_border_type:step");
  let backgroundWidth = 6;
  if (hover || click) {
    backgroundWidth = 10;
  }
  if (laneBorderType === undefined) {
    return [
      new Style({
        stroke: new Stroke({
          color: "lightgrey",
          width: 1,
        }),
      }),
    ];
  }
  if (laneBorderType.includes("solid")) {
    return [
      new Style({
        stroke: new Stroke({
          color: "grey",
          width: backgroundWidth,
        }),
      }),
      new Style({
        stroke: new Stroke({
          color: "white",
          width: 2,
        }),
      }),
    ];
  }
  if (laneBorderType.includes("dashed")) {
    return [
      new Style({
        stroke: new Stroke({
          color: "grey",
          width: backgroundWidth,
        }),
      }),
      new Style({
        stroke: new Stroke({
          color: "white",
          lineDash: [10, 5],
          width: 2,
        }),
      }),
    ];
  }
  if (laneBorderType.includes("road_border")) {
    return [
      new Style({
        stroke: new Stroke({
          color: "lightgrey",
          width: backgroundWidth,
        }),
      }),
      new Style({
        stroke: new Stroke({
          color: "grey",
          width: backgroundWidth,
        }),
      }),
    ];
  }
}
