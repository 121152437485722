import Select from "ol/interaction/Select";
import { click, pointerMove } from "ol/events/condition";
import {
  getModerationStyleClick,
  getModerationStyleHover,
} from "../style/moderation";

export function getModerationInteractions(layer, control) {
  const clickSelect = new Select({
    layers: [layer],
    condition: click,
    multi: true,
    style: function (feature) {
      return getModerationStyleClick(feature);
    },
    hitTolerance: 5,
  });
  clickSelect.on("select", function (e) {
    if (e.target.getFeatures().getLength() > 0) {
      control.set(
        "selectedFeatures",
        Array.from(e.target.getFeatures().getArray()),
      ); // create new array so that the event gets triggered
    } else {
      control.set("selectedFeatures", undefined);
    }
    layer.changed();
  });
  const hoverSelect = new Select({
    layers: [layer],
    condition: pointerMove,
    style: function (feature) {
      return getModerationStyleHover(feature);
    },
  });
  hoverSelect.on("select", function () {
    layer.changed();
  });
  return [clickSelect, hoverSelect];
}
