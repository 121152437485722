import { Stroke, Style } from "ol/style";
import { SUPPORTED_HIGHWAY_TYPES } from "./supportedHighwayTypes";
import {
  getNodeStyleNormal,
  getNodeStyleClick,
  getNodeStyleHover,
} from "./node";

export function getNavigabilityStyleNormal(feature) {
  return getNavigabilityColorStyle(feature).concat(getNodeStyleNormal(feature));
}
export function getNavigabilityStyleClick(feature) {
  return getNavigabilityColorStyle(feature, true, false).concat(
    getNodeStyleClick(feature),
  );
}
export function getNavigabilityStyleHover(feature) {
  return getNavigabilityColorStyle(feature, false, true).concat(
    getNodeStyleHover(feature),
  );
}
function getNavigabilityColorStyle(feature, click, hover) {
  let color, width;
  const orbisNavigability = feature.get("orbis-navigability");
  const osmHighway = feature.get("osm-highway");
  if (osmHighway === undefined) {
    color = "black";
    width = 1;
  } else if (!SUPPORTED_HIGHWAY_TYPES.includes(osmHighway)) {
    color = "grey";
    width = 1;
  } else if (orbisNavigability === "closed") {
    color = "red";
    width = 3;
  } else if (orbisNavigability === "no_through") {
    color = "orange";
    width = 3;
  } else if (orbisNavigability === "unknown") {
    color = "blue";
    width = 3;
  } else if (orbisNavigability === "open") {
    color = "green";
    width = 3;
  } else {
    color = "lightgreen";
    width = 1;
  }
  if (click) {
    return [
      new Style({
        stroke: new Stroke({
          color: color,
          width: width * 3,
        }),
      }),
      new Style({
        stroke: new Stroke({
          color: "orange",
          width: width * 2,
        }),
      }),
    ];
  }
  if (hover) {
    return [
      new Style({
        stroke: new Stroke({
          color: "white",
          width: width * 4,
        }),
      }),
      new Style({
        stroke: new Stroke({
          color: color,
          width: width * 2,
        }),
      }),
    ];
  }
  return [
    new Style({
      stroke: new Stroke({
        color: color,
        width: width,
      }),
    }),
  ];
}
