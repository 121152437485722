import Select from "ol/interaction/Select";
import { click, pointerMove } from "ol/events/condition";
import {
  getElevationStyleClick,
  getElevationStyleHover,
} from "../style/elevation";

export function getElevationInteractions(layer, control) {
  const clickSelect = new Select({
    layers: [layer],
    condition: click,
    style: function (feature) {
      return getElevationStyleClick(feature);
    },
  });
  clickSelect.on("select", function (e) {
    if (e.target.getFeatures().getLength() > 0) {
      control.set(
        "selectedFeatures",
        Array.from(e.target.getFeatures().getArray()),
      ); // create new array so that the event gets triggered
    } else {
      control.set("selectedFeatures", undefined);
    }
  });
  return [
    clickSelect,
    new Select({
      layers: [layer],
      condition: pointerMove,
      style: function (feature) {
        return getElevationStyleHover(feature);
      },
    }),
  ];
}
